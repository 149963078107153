import React from 'react'
import { Button } from '@arc-ui/button'
import { Heading } from '@arc-ui/typography'
import { FormattedMessage, useIntl } from 'react-intl'
import { ButtonsContainer, HighlightedText, Row } from '../ui'
import { useFormik } from 'formik'

const initialValues = {
  name: ''
}

const validate = ({ name }) => {
  const errors = {}

  if (!name) {
    errors.name = <FormattedMessage id='ERROR_EMPTY_DESCRIPTION' />
  } else if (name.length > 255) {
    errors.name = <FormattedMessage id='ERROR_DESCRIPTION_TOO_LONG' />
  }

  return errors
}

export function TokenForm ({
  onSubmit,
  onReset,
  tokenData
}) {
  const {
    isSubmitting,
    values,
    status,
    errors,
    handleSubmit,
    handleChange,
    handleReset
  } = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    onReset,
    validate
  })
  const intl = useIntl()

  return (
    <form
      className='token-form'
      onSubmit={handleSubmit}
      onReset={handleReset}
      autoComplete='off'
    >
      {tokenData && tokenData.description1 && (
        <Row>
          <HighlightedText
            styles={{
              color: 'red.500',
              fontSize: 'h3',
              lineHeights: 'h3'
            }}
            text={intl.formatMessage({ id: tokenData.description1 })}
            highlight={intl.formatMessage({ id: tokenData.description2 })}
          />
        </Row>
      )}
      <Row flexDirection='column'>
        <Heading level={3}>
          <FormattedMessage id='LABEL_DESCRIPTION' />
        </Heading>
        <div className='control'>
          <input
            name='name'
            className={'input' + (errors && errors.name ? ' is-danger' : '')}
            type='text'
            value={(values && values.name) || ''}
            onChange={handleChange}
          />
        </div>
        {errors && errors.name && (
          <p className='help is-danger'>{errors.name}</p>
        )}
      </Row>
      {status && status.message && (
        <div className='field has-text-danger'>{status.message}</div>
      )}
      <ButtonsContainer>
        <Button type='reset' className='default' size='small'>
          <FormattedMessage id='LABEL_CANCEL' />
        </Button>
        <Button
          type='submit'
          variant='primary'
          disabled={isSubmitting}
          size='small'
        >
          <FormattedMessage id='LABEL_CREATE' />
        </Button>
      </ButtonsContainer>
    </form>
  )
}
