import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App.jsx'
import 'promise-polyfill/src/polyfill'
import 'unfetch/polyfill'
/* react-intl dependencies */
import '@formatjs/intl-relativetimeformat/polyfill'
import 'intl-pluralrules'

import { ArcThemeProvider, Fonts } from '@arc-ui/system'
/* il8n */
import { IntlProvider } from 'react-intl'
import locales from './constants/locales'
import { theme } from '@arc-ui/theme'
import { UserProfileProvider } from './shared/hooks/global/useUseProfile/index.js'

const browserLocale = navigator.language.split(/[-_]/)[0]
const usersLocale = {}.hasOwnProperty.call(locales, browserLocale)
  ? browserLocale
  : 'en'
// Debug
// const usersLocale = "debug"

ReactDOM.render(
  <IntlProvider locale={usersLocale} messages={locales[usersLocale]}>
    <ArcThemeProvider theme={theme}>
      <Fonts />
      <UserProfileProvider>
        <App />
      </UserProfileProvider>
    </ArcThemeProvider>
  </IntlProvider>,
  document.getElementById('root')
)
