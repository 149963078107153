/* global fetch */
export const getUserInfo = async () => {
  const response = await fetch('/user.json', {
    method: 'GET',
    credentials: 'include'
  })

  return response.json()
}

export const getTokens = async (q, showAll) => {
  const params = {}
  if (q) { params.q = q }
  if (showAll) { params.showAll = '1' }

  const qs = Object.keys(params).map(k =>
    encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
  ).join('&')

  const response = await fetch(`/access/api/v2/tokens${qs ? '?' + qs : ''}`, {
    method: 'GET',
    credentials: 'include'
  })

  if (response.status >= 400) {
    const respText = await response.text()
    if (respText) {
      throw new Error(respText)
    } else {
      throw new Error(`${response.status} - ${response.statusText}`)
    }
  } else {
    return response.json()
  }
}

export const createToken = async ({ name, permissionScope }) => {
  const response = await fetch('/access/api/v2/tokens', {
    method: 'POST',
    body: JSON.stringify({ name, permissionScope }),
    headers: { 'content-type': 'application/json' },
    credentials: 'include'
  })

  if (!response.ok) {
    const body = await response.text()
    if (body) {
      throw new Error(body)
    }

    throw new Error(`Unable to create token: ${response.status} - ${response.statusText}`)
  }

  return response.json()
}

export const revokeToken = async (id) => {
  const response = await fetch(`/access/api/v2/tokens/${id}`, {
    method: 'DELETE',
    credentials: 'include'
  }).catch(err => console.log(err))

  if (!response.ok) {
    const body = await response.text()
    if (body) {
      throw new Error(body)
    }

    throw new Error(`Unable to revoke token: ${response.status} - ${response.statusText}`)
  }

  return `successfully revoked token: ${id}`
}

// Errand function...never invoked
// export const getOrgTokens = async () => {
//   const response = await fetch('/access/api/v2/admin/tokens', {
//     method: 'GET',
//     credentials: 'include'
//   })

//   return response.json()
// }
