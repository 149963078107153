import React, { useState } from 'react'
import useModal from '../../../shared/hooks/useModal/index.jsx'
import { TOKEN_PERMISSIONS } from '../../../constants/constants.js'
import { useIntl } from 'react-intl'
import CreateTokenForm from '../../CreateTokenForm/index.jsx'

function useCreateTokenModal () {
  const { Modal, open, close } = useModal()
  const [token, setToken] = useState()
  const [title, setTitle] = useState('')
  const intl = useIntl()

  function openModal (type) {
    const tokenData = { ...TOKEN_PERMISSIONS.find(({ key }) => key === type) }
    const tokenName =
      tokenData && tokenData.name
        ? intl.formatMessage({ id: tokenData.name })
        : ''

    const modalTitle = `${intl.formatMessage({
      id: 'LABEL_CREATE'
    })} ${tokenName} ${intl.formatMessage({
      id: 'LABEL_TOKEN_NAME'
    })}`

    setTitle(modalTitle)
    setToken(tokenData)
  }

  React.useEffect(() => {
    if (token) {
      open()
    }
  }, [token])

  function closeModal (onClose, created) {
    if (onClose) {
      onClose(created)
    }
    close()
  }

  const DeleteTokenModal = ({ onClose }) => (
    <Modal title={title}>
      <CreateTokenForm
        tokenData={token}
        onClose={(created) => closeModal(onClose, created)}
      />
    </Modal>)

  return [DeleteTokenModal, openModal, closeModal]
}

export default useCreateTokenModal
