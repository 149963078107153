const en = require('date-fns/locale/en-US')
const es = require('date-fns/locale/es')
const fr = require('date-fns/locale/fr')
const de = require('date-fns/locale/de')
const ja = require('date-fns/locale/ja')
const ko = require('date-fns/locale/ko')

export default {
  en,
  es,
  fr,
  de,
  ja,
  ko
}
