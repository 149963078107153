/** @jsx jsx */
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Heading } from '@arc-ui/typography'
import { Button } from '@arc-ui/button'
import { jsx } from '@arc-ui/system'
import { ButtonsContainer, Row } from '../ui'
import { Link } from 'react-router-dom'
import './styles.scss'

const CreatedToken = ({ created, onClose }) => {
  const intl = useIntl()
  const tokenRef = React.useRef()
  const [copiedKey, setCopiedKey] = useState(false)
  const [copiedCurl, setCopiedCurl] = useState(false)
  const [copiedPBEnv, setCopiedPBEnv] = useState(false)
  const [downloadedPBEnv, setDownloadPBEnv] = useState(false)

  const getCurlValue = () => {
    const token = created.token
    const basePath = window.location.origin.replace(
      /https?:\/\//,
      'https://api.'
    )
    return `curl -H "Content-Type: application/json" \\
   -H "Authorization: Bearer ${token}" \\
   -X GET ${basePath}/site/v3/website/`
  }

  const curlValue = getCurlValue()

  const getPBEnvValue = () => {
    const token = created.token
    const basePath = window.location.origin.replace(
      /https?:\/\//,
      'https://api.'
    )
    return `CONTENT_BASE=${basePath} \nARC_ACCESS_TOKEN=${token}`
  }

  const PBEnvValue = getPBEnvValue()

  const handleCopyPBEnv = () => {
    const ta = document.createElement('textarea')
    ta.value = PBEnvValue
    document.body.appendChild(ta)
    ta.select()
    document.execCommand('copy')
    setCopiedPBEnv(true)
    ta.remove()
  }

  const handleDownloadEnv = () => {
    const element = document.createElement('a')
    // Blob is not defined in nodejs which runs standardJS Rules
    const file = new Blob([PBEnvValue], { type: "application/octet-stream" }); // eslint-disable-line
    element.href = URL.createObjectURL(file)
    element.download = 'env'
    document.body.appendChild(element)
    element.click()
  }

  const handleCopyCurl = () => {
    const ta = document.createElement('textarea')
    ta.value = curlValue
    document.body.appendChild(ta)
    ta.select()
    document.execCommand('copy')
    setCopiedCurl(true)
    ta.remove()
  }

  const handleCopyKey = () => {
    tokenRef.current.select()
    document.execCommand('copy')
    setCopiedKey(true)
  }

  return (
    <>
      <Row>
        <Heading level={3}>
          <FormattedMessage id='ACTION_CREATE_TOKEN_COMPLETE_TITLE' />
        </Heading>
      </Row>

      <Row marginBottom='xxsmall'>
        <div className='copyKey'>
          <input
            readOnly
            className='input'
            sx={{ flex: 1 }}
            type='text'
            value={created.token}
            ref={tokenRef}
          />
          <Button
            className='tooltip default'
            size='small'
            sx={{ marginLeft: 'xsmall' }}
            onClick={handleCopyKey}
            onMouseLeave={() => setCopiedKey(false)}
            data-tooltip={
              copiedKey
                ? intl.formatMessage({ id: 'LABEL_COPIED' })
                : intl.formatMessage({ id: 'LABEL_CLIPBOARD' })
            }
          >
            <FormattedMessage id='LABEL_COPY' />
          </Button>
        </div>
      </Row>
      <Row>
        <Heading level={3} sx={{ color: 'red.500' }}>
          <FormattedMessage id='ACTION_CREATE_TOKEN_COMPLETE_EPHEMWARNING' />
        </Heading>
      </Row>
      <Row sxStyles={{ marginTop: 'medium' }}>
        <Heading level={3}>
          <FormattedMessage id='ACTION_CREATE_TOKEN_COMPLETE_HELPER_DESC_AUTH' />
        </Heading>
      </Row>
      <Row>
        <pre className='pre-curl'>{curlValue}</pre>
      </Row>
      <ButtonsContainer>
        <Button
          size='small'
          className='tooltip default'
          onClick={handleCopyCurl}
          onMouseLeave={() => setCopiedCurl(false)}
          data-tooltip={
            copiedCurl
              ? intl.formatMessage({ id: 'LABEL_COPIED' })
              : intl.formatMessage({ id: 'LABEL_CLIPBOARD' })
          }
        >
          <FormattedMessage id='LABEL_COPY' />
        </Button>
      </ButtonsContainer>
      <Row sxStyles={{ marginTop: 'medium' }}>
        <Heading level={3}>
          <FormattedMessage id='ACTION_CREATE_TOKEN_COMPLETE_HELPER_FILE' />
          <Link to='/alc/arc-products/pagebuilder/fusion/documentation/recipes/configuring-feature-pack.md'>
            <FormattedMessage id='ACTION_CREATE_TOKEN_COMPLETE_HELPER_FILE_LINK' />
          </Link>
        </Heading>
      </Row>
      <Row>
        <pre className='pre-curl'>{PBEnvValue}</pre>
      </Row>
      <ButtonsContainer>
        <Button
          size='small'
          className='tooltip default'
          onClick={handleCopyPBEnv}
          onMouseLeave={() => setCopiedPBEnv(false)}
          data-tooltip={
            copiedPBEnv
              ? intl.formatMessage({ id: 'LABEL_COPIED' })
              : intl.formatMessage({ id: 'LABEL_CLIPBOARD' })
          }
        >
          <FormattedMessage id='LABEL_COPY' />
        </Button>
        <Button
          size='small'
          className='tooltip default'
          onClick={handleDownloadEnv}
          onMouseLeave={() => setDownloadPBEnv(false)}
          data-tooltip={
            downloadedPBEnv
              ? intl.formatMessage({ id: 'LABEL_DOWNLOADED' })
              : intl.formatMessage({ id: 'LABEL_DOWNLOADTOSYSTEM' })
          }
        >
          <FormattedMessage id='LABEL_DOWNLOAD' />
        </Button>
      </ButtonsContainer>

      <ButtonsContainer>
        <Button variant='primary' onClick={onClose} size='small'>
          <FormattedMessage id='LABEL_CLOSE' />
        </Button>
      </ButtonsContainer>
    </>
  )
}

export default CreatedToken
