import React from 'react'
import './index.scss'
import { Button } from '@arc-ui/button'

const ButtonGroup = ({ buttonData, title }) => {
  return (
    <div className='button-group'>
      <h3 className='button-group-title'>{title}</h3>
      {buttonData.map(({ title, onClick, variant, className }, i) => (
        <Button
          variant={variant}
          onClick={onClick}
          key={i}
          className={className}
          size='small'
        >
          {title}
        </Button>
      ))}
    </div>
  )
}

export default ButtonGroup
