import React from 'react'
import { getUserInfo } from '../../../api/UserProfile'
import Loader from '../../../../components/Loader.jsx'
import FetchGlobalSettings, { defaultSettings } from '../../../utils/settings'
import Flex from '../../../../components/ui/layout/Flex'

export const UserProfileContext = React.createContext()

export const UserProfileProvider = (props) => {
  const [user, setUser] = React.useState(null)
  const [globalSettings, setGlobalSettings] = React.useState(defaultSettings)

  async function loadUser () {
    const userData = await getUserInfo()
    setUser(userData)
  }

  async function loadGlobalSettings () {
    const settings = await FetchGlobalSettings()
    setGlobalSettings(settings)
  }

  React.useEffect(() => {
    loadGlobalSettings()
    loadUser()
  }, [])

  return (
    <UserProfileContext.Provider value={{ user, globalSettings }}>
      {user ? (
        props.children
      ) : (
        <Flex
          sx={{
            width: '100vw',
            height: '100vh',
            justifyContent: 'center'
          }}
        >
          <Loader />
        </Flex>
      )}
    </UserProfileContext.Provider>
  )
}
