import Fetch from '../../utils/Fetch'

export const getTokens = ({ q, showAll, sortBy } = {}) => {
  const params = {}
  if (q) {
    params.q = q
  }
  if (showAll) {
    params.showAll = '1'
  }
  if (sortBy) {
    params.sortBy = sortBy.id
    params.orderBy = sortBy.desc ? 'desc' : 'asc'
  }

  return Fetch.get('/access/api/v2/tokens', { params })
}

export const createToken = ({ name, permissionScope }) => {
  return Fetch.post('/access/api/v2/tokens', { name, permissionScope })
}

export const revokeToken = (id) => {
  return Fetch.post(`/access/api/v2/tokens/${id}`)
}
