import React, { useState } from 'react'
import useModal from '../../../shared/hooks/useModal/index.jsx'
import RevokeTokenPrompt from '../../RevokeTokenPrompt.jsx'

function useDeleteTokenModal () {
  const { Modal, open, close } = useModal()
  const [token, setToken] = useState()

  function openModal (token) {
    setToken(token)
  }

  React.useEffect(() => {
    if (token) {
      open()
    }
  }, [token])

  function closeModal (onClose, id) {
    if (onClose) {
      onClose({ id })
    }
    close()
  }

  const DeleteTokenModal = ({ onClose }) => (
    <Modal>
      <RevokeTokenPrompt
        token={token}
        onClose={(tokenId) => closeModal(onClose, tokenId)}
      />
    </Modal>)

  return [DeleteTokenModal, openModal, closeModal]
}

export default useDeleteTokenModal
