import React from 'react'

function useFetcherFactory (action) {
  return function useFetcher () {
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [data, setData] = React.useState([])
    const [params, setParams] = React.useState(null)

    async function addItem (item) {
      try {
        setLoading(true)
        if (item) { setData([...data, item]) }
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }

    async function removeItem (item) {
      try {
        setLoading(true)
        if (item) { setData(data.filter((i) => i.id !== item.id)) }
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }

    async function loadData () {
      try {
        setLoading(true)
        const actionData = await action(params)
        setData(actionData)
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }
    React.useEffect(() => {
      if (params) {
        loadData()
      }
    }, [params])

    return { data, loading, error, loadData, setParams, addItem, removeItem }
  }
}
export default useFetcherFactory
