import en from './en'
import es from './es'
import fr from './fr'
import de from './de'
import ja from './ja'
import ko from './ko'

export default {
  en,
  es,
  fr,
  de,
  ja,
  ko
}
