/** @jsx jsx */
import { jsx } from '@arc-ui/system'
import { Heading } from '@arc-ui/typography'
import React, { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { animated, useTransition } from 'react-spring'
import { Row } from '../../../components/ui'
import styles from './styles.module.scss'

const useDialogContentStyle = {
  bg: 'white',
  outline: 'none',
  margin: '10vh auto',
  padding: 'medium',
  width: ['100vw', '600px', '800px']
}

const ModalOverlay = ({ backdrop, close, children, style }) => (
  <>
    <div className={styles.maskStyle} style={style} onClick={() => { backdrop !== 'static' && close() }} />
    {children}
  </>)

const ModalContent = ({ children, style }) => <div className={styles.containerStyle} sx={useDialogContentStyle} style={style}>{children}</div>

const AnimatedOverlay = animated(ModalOverlay)
const AnimatedContent = animated(ModalContent)

export const dialogSpring = {
  from: { opacity: 0, transform: 'translateY(8px)' },
  enter: { opacity: 1, transform: 'translateY(0)' },
  leave: { opacity: 0, transform: 'translateY(8px)' }
}

const Modal = ({ children, isOpen = false, close, elementId = 'root', title, backdrop = 'static' }) => {
  const transitions = useTransition(isOpen, null, dialogSpring)

  if (isOpen === false) {
    return null
  }
  return createPortal(
    <div className={styles.wrapperStyle}>
      {transitions.map(
        ({ item, key, props: styles }) =>
          item && (
            <AnimatedOverlay
              backdrop={backdrop} close={close} key={key}
              style={{ opacity: styles.opacity }}
            >
              <AnimatedContent style={{ transform: styles.transform }}>
                {title && (
                  <Row>
                    <Heading>{title}</Heading>
                  </Row>
                )}
                <section>{children}</section>
              </AnimatedContent>
            </AnimatedOverlay>
          ))}
    </div>,
    document.getElementById(elementId)
  )
}

const useModal = (elementId = 'root', { backdrop = 'static' } = {}) => {
  const [isOpen, setOpen] = useState(false)

  const open = React.useCallback(() => setOpen(true), [setOpen])
  const close = React.useCallback(() => setOpen(false), [setOpen])

  const ModalWrapper = useCallback(
    ({ children, title }) => {
      return (
        <Modal isOpen={isOpen} close={close} elementId={elementId} title={title} backdrop={backdrop}>
          {children}
        </Modal>
      )
    },
    [isOpen, close, elementId]
  )

  return { Modal: ModalWrapper, open, close, isOpen }
}

export default useModal
