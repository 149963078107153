import React from 'react'
import { createToken } from '../../api.js'
import CreatedToken from './CreatedToken.jsx'
import { TokenForm } from './TokenForm.jsx'

function CreateTokenForm (props) {
  const [created, setCreated] = React.useState()
  const { tokenData = {} } = props

  const handleCreate = async (values, actions) => {
    const createTokenValues = {
      ...values,
      permissionScope: tokenData.key
    }
    try {
      const response = await createToken(createTokenValues)
      setCreated(response)
    } catch (error) {
      actions.setStatus(error)
    }
    actions.setSubmitting(false)
  }

  const handleClose = React.useCallback(() => {
    props.onClose && props.onClose(created)
  }, [props.onClose, created])

  if (created) {
    return (
      <CreatedToken
        created={created}
        onClose={handleClose}
      />
    )
  }
  return (
    <TokenForm
      onSubmit={handleCreate}
      onReset={handleClose}
      tokenData={tokenData}
    />
  )
}

export default CreateTokenForm
