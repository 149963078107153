/** @jsx jsx */
import { jsx } from '@arc-ui/system'

const Flex = ({ children, sx, ...props }) => (
  <div className='level-item' sx={sx} {...props}>
    {children}
  </div>
)

export default Flex
