import React from 'react'
import './Loader.scss'
import logo from '../assets/img/arc.png'

const Loader = () => (
  <div className='arc-loader'>
    <img src={logo} />
    <div className='arc-loader-circle' />
  </div>
)

export default Loader
