import { Heading } from '@arc-ui/typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDebounce } from '../../../shared/hooks'
import './SearchBox.scss'

const SearchBox = ({ placeholder, onChange, onClear, isLoading }) => {
  const [searchTerm, setSearchTerm] = useState()
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const textInput = React.createRef()

  useEffect(() => {
    if (debouncedSearchTerm !== undefined) {
      onChange(searchTerm)
    }
  }, [debouncedSearchTerm])

  const _onClear = () => {
    textInput.current.value = ''
    onClear()
  }

  return (
    <div className='search-box field'>
      <Heading level={3}>{placeholder}</Heading>
      <div
        className={`control has-icons-left has-icons-right${
          isLoading ? ' is-loading' : ''
        }`}
      >
        <input
          data-testid='search-box-input'
          className='input'
          type='text'
          ref={textInput}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={placeholder}
        />
        <span className='icon is-left'>
          <FontAwesomeIcon icon='search' />
        </span>
        {!isLoading && searchTerm && onClear && (
          <span className='icon is-right clear-search' onClick={_onClear} data-testid='search-box-clear-icon'>
            <FontAwesomeIcon icon='times' />
          </span>
        )}
      </div>
    </div>
  )
}

export default SearchBox
