/** @jsx jsx */
import { jsx } from '@arc-ui/system'
import { Text, Heading } from '@arc-ui/typography'

const HighlightedText = ({ text = '', highlight = '', styles = {} }) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  return (
    <Heading level={3}>
      {parts.map((part, i) => (
        part.toLowerCase() === highlight.toLowerCase()
          ? (
            <Text
              as='span'
              key={i}
              sx={styles}
            >
              {part}
            </Text>)
          : part
      ))}
    </Heading>
  )
}

export default HighlightedText
