/** @jsx jsx */
import { jsx } from '@arc-ui/system'

const Row = ({
  children,
  marginBottom = 'xsmall',
  flexDirection = 'row',
  justifyContent = 'normal',
  sxStyles = {},
  ...props
}) => (
  <div
    {...props}
    sx={{
      display: 'flex',
      marginBottom,
      flexDirection,
      justifyContent,
      ...sxStyles
    }}
  >
    {children}
  </div>
)

export default Row
