import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import Analytics from 'react-router-ga'
import '../icons.js'
import './App.scss'
import { FormattedMessage } from 'react-intl'
import { TokenList } from './TokenList/index.jsx'

const config = {
  appname: <FormattedMessage id='APP_NAME' />,
  links: [
    {
      name: <FormattedMessage id='LABEL_TOKEN_NAME_PL' />,
      match: {
        path: '/developer/access/'
      },
      sub: []
    }
  ],
  dropdownLinks: []
}

const setGoogleAnalyticsID = () => {
  let GA_ID = null

  if (!process.env.ENVIRONMENT) {
    console.log('process.env.ENVIRONMENT is not set')
    return null
  }

  if (process.env.ENVIRONMENT === 'prod') {
    GA_ID = 'UA-118623570-19'
  } else if (process.env.ENVIRONMENT === 'sandbox') {
    GA_ID = 'UA-118623570-20'
  }

  return GA_ID
}

const { ArcNav } = window

const App = () => {
  return (
    <Router>
      <Analytics id={setGoogleAnalyticsID()}>
        <ArcNav {...config} />

        <Route exact path='/' render={() => (<Redirect to='/developer/access/tokens' />)} />
        <Route exact path='/developer' render={() => (<Redirect to='/developer/access/tokens' />)} />
        <Route exact path='/developer/access/tokens' component={TokenList} />
      </Analytics>
    </Router>
  )
}

export default App
