'use strict'

class Permissions {
  constructor (p) {
    this._p = 0

    if (!p || !p['API Access'] || !p['API Access'].global) {
      return
    }

    const privileges = p['API Access'].global

    if (privileges.constructor !== Array) {
      return
    }

    for (const priv of privileges) {
      switch (priv) {
        case 'E':
          this._p |= 1
          break
        case 'VA':
          this._p |= 1 << 1
          break
        case 'EA':
          this._p |= 1 << 2
          break
        case 'R':
          this._p |= 1 << 3
      }
    }
  }

  canEdit () {
    return (this._p & 1) > 0
  }

  canViewOrg () {
    return (this._p & 1 << 1) > 0
  }

  canEditOrg () {
    return (this._p & 1 << 2) > 0
  }

  canEditReadOnly () {
    return (this._p & 1 << 3) > 0
  }
}

export default Permissions
