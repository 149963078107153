
import { formatDistanceToNow, parseISO, toDate } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import dateLocales from '../../dateLocales'

const usersLocale = navigator.language.split(/[-_]/)[0]
const locale = dateLocales[usersLocale]

const TokenTimestamp = (
  timestamp, defaultValue
) => {
  if (timestamp) { return formatDistanceToNow(toDate(parseISO(timestamp)), { locale: locale, addSuffix: true }) }
  return defaultValue
}

const formatTS = (settings, timestamp) => {
  if (!timestamp) { return null }
  const zonedTimestamp = (() => {
    const ISOParsedTimestamp = parseISO(timestamp)
    const zonedTimestamp = utcToZonedTime(ISOParsedTimestamp, settings.timezone)
    if (zonedTimestamp) return zonedTimestamp
    return utcToZonedTime(ISOParsedTimestamp, 'America/New_York')
  })()

  return format(zonedTimestamp, settings.datetimeFormat, { locale: locale, timeZone: settings.timezone })
}

export { TokenTimestamp, formatTS }
