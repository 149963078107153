import { injectIntl } from 'react-intl'

export function camelCaseToSentence (str) {
  const res = str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')
  return res.charAt(0).toUpperCase() + res.slice(1)
}

function checkPermissionScope ({ permissions, checkScope, intl }) {
  // performs text comparison for efficiency, only valid because defaults are explicitly created
  const stripped = checkScope.replace(/ /g, '')
  // stripped = {"ALL":["*"]}  permissions[0] is admin at constants
  if (stripped === permissions[0].defaultPermissions) {
    return intl.formatMessage({ id: 'TOKEN_API_TYPE_ADMIN_NAME' })
  }
  // stripped = {"GET":["*"],"HEAD":["*"],"OPTIONS":["*"],"POST":["/content/v4/urls"]}  permissions[1] is readonly at constants
  if (stripped === permissions[1].defaultPermissions || stripped === permissions[1].defaultPermissionsLegacy) {
    return intl.formatMessage({ id: 'TOKEN_API_TYPE_READONLY_NAME' })
  }
  return '({intl.formatMessage({ id: \'TOKEN_API_TYPE_CUSTOM\'} )})'
}

export function CheckPermissionsScope ({
  permissions,
  checkScope,
  messages = {}
}) {
  // performs text comparison for efficiency, only valid because defaults are explicitly created
  const stripped = checkScope.replace(/ /g, '')
  // stripped = {"ALL":["*"]}  permissions[0] is admin at constants
  if (stripped === permissions[0].defaultPermissions) {
    return messages.TOKEN_API_TYPE_ADMIN_NAME
  }
  // stripped = {"GET":["*"],"HEAD":["*"],"OPTIONS":["*"],"POST":["/content/v4/urls"]}  permissions[1] is readonly at constants
  if (stripped === permissions[1].defaultPermissions || stripped === permissions[1].defaultPermissionsLegacy) {
    return messages.TOKEN_API_TYPE_READONLY_NAME
  }
  return messages.TOKEN_API_TYPE_CUSTOM
}

export const CheckPermissionScope = injectIntl(checkPermissionScope)
