import { Heading } from '@arc-ui/typography'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { TOKEN_PERMISSIONS } from '../../constants/constants'
import Permissions from '../../permissions'
import { TokenDataAdapter } from '../../shared/adapters/TokenAdapters'
import { UserProfileContext } from '../../shared/hooks/global/useUseProfile'
import { useTokenData } from '../../shared/hooks/useTokenData'
import ButtonGroup from '../ButtonGroup'
import useCreateTokenModal from '../modals/useCreateTokenModal'
import useDeleteTokenModal from '../modals/useDeleteTokenModal'
import TokenTable from '../TokenTable'
import { Row } from '../ui'
import SearchBox from '../ui/SearchBox'
import Flex from '../ui/layout/Flex'

export function TokenList () {
  const intl = useIntl()
  const { user, globalSettings } = useContext(UserProfileContext)
  const { data, loading, error, setParams, removeItem: removeToken, addItem: addToken } = useTokenData()
  const [tokens, setTokens] = useState([])
  const [userPermissions, setUserPermissions] = useState()
  const [form, setForm] = useState()
  const [DeleteTokenModal, openDeleteModal] = useDeleteTokenModal()
  const [CreateTokenModal, openCreateModal] = useCreateTokenModal()

  useEffect(() => {
    const permissions = new Permissions(user && user.permissions)
    const getEditableTokens = () => {
      if (permissions.canEdit()) return ['general', 'readOnly']
      if (permissions.canEditReadOnly()) return ['readOnly']
      return []
    }
    setUserPermissions({
      authority: (user && user.authority) || '',
      username: (user && user.username) || '',
      permissions,
      editableTokens: getEditableTokens()
    })
  }, [])

  useEffect(() => {
    if (userPermissions) {
      const showAllTokensByPermissions = userPermissions.permissions.canViewOrg()
      setForm({ q: '', showAll: showAllTokensByPermissions })
    }
  }, [userPermissions])

  useEffect(() => {
    const messages = [
      'TOKEN_API_TYPE_ADMIN_NAME',
      'TOKEN_API_TYPE_READONLY_NAME',
      'TOKEN_API_TYPE_CUSTOM',
      'LABEL_NO_USAGE'
    ].reduce((prev, id) => ({ ...prev, [id]: intl.formatMessage({ id }) }), [])

    setTokens(
      data.map((token) => TokenDataAdapter(token, globalSettings, messages))
    )
  }, [data])

  useEffect(() => {
    if (form) { setParams(form) }
  }, [form])

  function onChangeSearchTerm (q = '') {
    setForm({ ...form, q })
  }

  return (
    <section className='section'>
      <div className='container'>
        <Row>
          <div className='level-left'>
            <Flex>
              <Heading>
                <FormattedMessage id='LABEL_TOKEN_NAME_PL' />
              </Heading>
            </Flex>
          </div>
        </Row>
        <Row>
          <Heading level={3}>
            <FormattedMessage id='ACTION_LIST_TOKENS_DESC' />
          </Heading>
        </Row>
        <Row sxStyles={{ alignItems: 'flex-end' }} justifyContent='space-between'>
          <div className='level-left'>
            <Flex>
              <SearchBox
                placeholder={intl.formatMessage({
                  id: 'ACTION_SEARCH_SEARCHBOX_PLACEHOLDER'
                })}
                onChange={onChangeSearchTerm}
                onClear={onChangeSearchTerm}
                isLoading={loading}
              />
            </Flex>
          </div>
          {userPermissions &&
            <div className='level-right'>
              <Flex>
                <ButtonGroup
                  buttonData={TOKEN_PERMISSIONS.filter(({ key }) =>
                    userPermissions.editableTokens.includes(key)
                  ).map(({ name, classNames, key }) => {
                    return {
                      title: `${intl.formatMessage({
                        id: 'LABEL_CREATE'
                      })} ${intl.formatMessage({
                        id: name
                      })} ${intl.formatMessage({
                        id: 'LABEL_TOKEN'
                      })}`,
                      onClick: () => openCreateModal(key),
                      className: classNames.button || '',
                      variant: classNames.variant
                    }
                  })}
                />
              </Flex>
            </div>}
        </Row>
        {
          userPermissions && (
            <>
              <TokenTable
                data={tokens}
                permissions={userPermissions.permissions}
                authority={userPermissions.authority}
                username={userPermissions.username}
                confirmRevoke={openDeleteModal}
                loading={loading}
                error={error}
              />
            </>)
        }
      </div>
      <CreateTokenModal onClose={addToken} />
      <DeleteTokenModal onClose={removeToken} />
    </section>
  )
}
