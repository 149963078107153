/* global fetch */
async function request (url, method, config = {}, data) {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }

  if (config.params) {
    url += '?' + objectToQueryString(config.params)
  }

  if (data) {
    options.body = JSON.stringify(config.data)
  }

  const response = await fetch(url, options)

  if (response.status >= 400) {
    const respText = await response.text()
    if (respText) {
      throw new Error(respText)
    } else {
      throw new Error(`${response.status} - ${response.statusText}`)
    }
  }
  const result = await response.json()
  return result
}

function objectToQueryString (obj) {
  return Object.keys(obj)
    .map((key) => key + '=' + obj[key])
    .join('&')
}

function get (url, config) {
  return request(url, 'GET', config)
}

function post (url, data, config) {
  return request(url, 'POST', config, data)
}

function put (url, data, config) {
  return request(url, 'PUT', config, data)
}

function remove (url, config) {
  return request(url, 'DELETE', config)
}

export default {
  get,
  post,
  put,
  delete: remove
}
