import React from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import { Button } from '@arc-ui/button'
import { Text } from '@arc-ui/typography'
import { FormattedMessage } from 'react-intl'
import TokenRow from '../TokenRow/index.jsx'
import styles from './styles.module.scss'
import { ButtonsContainer, Row } from '../ui/index.js'
import Loader from '../Loader.jsx'

const Strong = ({ children }) => (
  <Text as='strong'>{children}</Text>
)

const columns = [
  {
    Header: 'Description',
    accessor: 'name'
  },
  {
    Header: 'Username',
    accessor: 'username'
  },
  {
    Header: 'Created',
    accessor: 'issued',
    sortInverted: true
  },
  {
    Header: 'Last used',
    accessor: 'last_used',
    sortInverted: true
  },
  {
    Header: 'Type',
    accessor: 'permissionScope'
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true
  }
]
function TokenTable ({
  data = [],
  permissions,
  viewOrg,
  authority,
  username,
  confirmRevoke,
  loading,
  error
}) {
  const options = {
    columns,
    data,
    initialState: { pageSize: 15 }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    rows,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize }
  } = useTable(
    options,
    useSortBy,
    usePagination
  )

  const from = pageIndex * pageSize + 1
  let to = (pageIndex + 1) * pageSize
  if (to > rows.length) {
    to = rows.length
  }

  if (loading) {
    return (
      <Row sxStyles={{ justifyContent: 'center', alignItems: 'center' }}>
        <Loader />
      </Row>
    )
  }

  if (error) {
    return (
      <div className='tile box has-text-danger'>
        <Text>
          <Text as='strong'>
            <FormattedMessage id='LABEL_ERROR' />:
          </Text>{' '}
          {error.message}
        </Text>
      </div>
    )
  }

  if (rows.length === 0) {
    return (
      <p className='empty-list is-size-4 has-text-dark has-text-centered'>
        <FormattedMessage id='ACTION_LIST_TOKENS_MSG_NOTOKENS' />
      </p>
    )
  }

  return (
    <>
      <table
        className={`table is-fullwidth ${styles.table}`}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, keyColumn) => (
                <th
                  key={keyColumn}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <Text>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' ▼'
                          : ' ▲'
                        : ''}
                    </span>
                  </Text>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            const { original } = row
            prepareRow(row)
            const props = { ...original }
            const canRevoke = !viewOrg ||
              permissions.canEditOrg() ||
              (original.authority === authority && original.username === username)
            if (canRevoke) {
              props.onRevoke = () => confirmRevoke(original)
            }
            return (
              <TokenRow
                key={original.id}
                {...props}
              />
            )
          })}
        </tbody>
      </table>
      <ButtonsContainer className={styles.pagination} marginLeft='small'>
        <div>
          <Strong>
            <FormattedMessage
              id='TOKEN_TABLE_ITEMS_LABEL'
              values={{ from, to, total: rows.length }}
            />
          </Strong>
        </div>
        <span>
          <select
            className={styles.selectPage}
            value={pageIndex}
            onChange={(e) => {
              gotoPage(Number(e.target.value))
            }}
          >
            {pageOptions.map((pageIndex) => (
              <option key={pageIndex} value={pageIndex}>
                {pageIndex + 1}
              </option>
            ))}
          </select>
          <Strong>
            <FormattedMessage
              id='TOKEN_TABLE_PAGES_LABEL'
              values={{ pages: pageOptions.length }}
            />
          </Strong>
        </span>
        <Button
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          {'◀'}
        </Button>
        <Button
          onClick={nextPage}
          disabled={!canNextPage}
        >
          {'▶'}
        </Button>
      </ButtonsContainer>
    </>
  )
}

export default TokenTable
