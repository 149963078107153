/* global fetch */
import { get } from 'lodash'

async function fetchGlobalSettings () {
  return fetch('/settings-readonly/api/v1/organization', {
    credentials: 'same-origin'
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(`Settings request rejected with status ${response.status}`)
      }
      return response.json()
    })
    .catch((error) => {
      throw error
    })
}

// Our library doesn't support legacy time zones, so we need to replace them for US
// http://ftp.iana.org/tz/tzdb-2016g/backward
const legacyTZMap = {
  'US/Alaska': 'America/Anchorage',
  'US/Aleutian': 'America/Adak',
  'US/Arizona': 'America/Phoenix',
  'US/Central': 'America/Chicago',
  'US/East-Indiana': 'America/Indiana/Indianapolis',
  'US/Eastern': 'America/New_York',
  'US/Hawaii': 'Pacific/Honolulu',
  'US/Indiana-Starke': 'America/Indiana/Knox',
  'US/Michigan': 'America/Detroit',
  'US/Mountain': 'America/Denver',
  'US/Pacific': 'America/Los_Angeles',
  'US/Samoa': 'Pacific/Pago_Pago'
}

export const defaultSettings = {
  datetimeFormat: 'PP pp xxx',
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

const FetchGlobalSettings = async () => {
  const settingsResponse = { ...defaultSettings }
  try {
    const globalSettings = await fetchGlobalSettings()
    let timezone = get(globalSettings, 'extra.timezone')
    if (timezone != null) {
      if (Object.prototype.hasOwnProperty.call(legacyTZMap, timezone)) {
        timezone = legacyTZMap[timezone]
      }
      settingsResponse.timezone = timezone
    }
    const timeHourFormat = get(globalSettings, 'extra.timeHourFormat')
    if (timeHourFormat === 24) {
      settingsResponse.datetimeFormat = 'PP HH:mm:ss xxx'
    } else if (timeHourFormat === 12) {
      settingsResponse.datetimeFormat = 'PP hh:mm:ss aa xxx'
    }
  } catch (error) {
    // It's already defaulted, catch and pass
  }
  return settingsResponse
}

export default FetchGlobalSettings
