import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@arc-ui/button'
import { revokeToken } from '../api.js'
import { ButtonsContainer, Row } from './ui'
import { Heading, Text } from '@arc-ui/typography'

const defaultState = {
  status: 'pending',
  errMsg: ''
}

function RevokeTokenPrompt (props) {
  const [status, setStatus] = React.useState(defaultState)
  const { token = {}, onClose } = props
  const { name, username: createdBy } = token

  const handleResetForm = (result) => {
    onClose && onClose(result)
  }

  const handleRevoke = async () => {
    try {
      const result = revokeToken(token.id)
      if (result) {
        handleResetForm(token.id)
      }
    } catch (err) {
      setStatus({
        status: 'error',
        errMsg: err.message
      })
    }
  }

  if (status === 'error') {
    return (
      <>
        <Row>
          <Heading>
            <FormattedMessage id='ACTION_REVOKE_TOKEN_REVOKE_FAIL' />!
          </Heading>
        </Row>
        <Row className='field has-text-centered'>
          <Text>
            <FormattedMessage id='ACTION_REVOKE_TOKEN_RESULT_FAIL_PERMISSION' />
          </Text>
        </Row>
        <ButtonsContainer>
          <Button onClick={handleResetForm}>
            <FormattedMessage id='LABEL_CANCEL' />
          </Button>
        </ButtonsContainer>
      </>
    )
  }

  return (
    <>
      <Row>
        <Heading>
          <FormattedMessage id='ACTION_REVOKE_TOKEN_REVOKECHECK' />
        </Heading>
      </Row>
      <Row>
        <Heading level={3}>
          <FormattedMessage
            id='ACTION_REVOKE_TOKEN_RESULTWARNING'
            values={{ name, createdBy }}
          />
        </Heading>
      </Row>
      <ButtonsContainer>
        <Button onClick={onClose} size='small' className='default'>
          <FormattedMessage id='LABEL_CANCEL' />
        </Button>
        <Button
          variant='destructive'
          onClick={handleRevoke}
          size='small'
        >
          <FormattedMessage id='LABEL_REVOKE' />
        </Button>
      </ButtonsContainer>
    </>
  )
}

export default RevokeTokenPrompt
