import { formatTS, TokenTimestamp } from '../TokenTimestamp'
import { TOKEN_PERMISSIONS } from '../../../constants/constants'
import { CheckPermissionsScope } from '../../utils/strings'

export function TokenDataAdapter (token, globalSettings, messages = {}) {
  const { issued, last_used: lastUsed } = token
  return {
    ...token,
    issued: (new Date(issued)).getTime(),
    last_used: lastUsed ? (new Date(lastUsed)).getTime() : null,
    issuedStr: TokenTimestamp(issued),
    lastUsedStr: TokenTimestamp(lastUsed, messages.LABEL_NO_USAGE),
    issuedTS: formatTS(globalSettings, issued),
    lastUsedTS: formatTS(globalSettings, lastUsed),
    type: CheckPermissionsScope({
      permissions: TOKEN_PERMISSIONS,
      checkScope: token.permissionScope,
      messages
    })

  }
}
