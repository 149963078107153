import { Button } from '@arc-ui/button'
import { Text } from '@arc-ui/typography'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import './index.scss'

const TokenRow = ({
  username,
  name,
  issuedTS,
  issuedStr,
  lastUsedTS,
  lastUsedStr,
  onRevoke,
  type
}) => (
  <tr className='token'>
    <td>{name}</td>
    <td title={username}>{username}</td>
    <td title={issuedTS}>{issuedStr}</td>
    <td title={lastUsedTS}>{lastUsedStr}</td>
    <td title={type}>{type}</td>
    <td className='action-column'>
      {onRevoke && (
        <Button
          size='small'
          onClick={onRevoke}
          sx={{
            border: 'none',
            color: 'red.500',
            fontWeight: 'regular',
            background: 'none',
            '&:focus': {
              borderColor: 'gray.500'
            }
          }}
        >
          <Text as='span'>
            <FormattedMessage id='ACTION_LIST_TOKENS_REVOKE' />
          </Text>
        </Button>
      )}
    </td>
  </tr>
)

export default TokenRow
