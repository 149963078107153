/** @jsx jsx */
import { jsx } from '@arc-ui/system'

const ButtonsContainer = ({
  children,
  className,
  marginLeft = 'xsmall',
  ...props
}) => (
  <div
    className={['buttons', className].join(' ')}
    {...props}
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      '& > *': {
        marginLeft
      }
    }}
  >
    {children}
  </div>
)

export default ButtonsContainer
